<template>
  <div>
    <div class="d-flex align-center px-8 mt-8 mb-3" v-if="tabLists.length > 0">
      <div style="border-top:1px solid #D1D6DB;height:1px;flex:3"></div>
      <div class="text-caption mx-2">
        其他登录方式
      </div>
      <div style="border-top:1px solid #D1D6DB;height:1px;flex:3"></div>
    </div>
    <div
      class="d-flex flex-column px-6"
      @click="curTab(item)"
      v-for="item in tabLists"
      :key="item.id"
    >
      <v-btn
        style=" background: white;border:1px solid #D1D6DC;height:48px"
        class="ma-1 black--text d-flex justify-start align-center"
        depressed
      >
        <v-icon
          v-if="item.type === 'PASSWORD'"
          class="mr-4"
          size="26"
          color="#0070CC"
        >
          mdi-form-textbox-lock
        </v-icon>
        <v-img
          v-else
          :src="require('@/assets/sources/' + item.type + '.png')"
          max-width="26"
          class="mr-4"
        ></v-img>
        使用 {{ item.name }}
      </v-btn>
    </div>
  </div>
</template>

<script>
export default {
  name: "MobileOtherLink",
  props: ["tabLists"],
  data() {
    return {
      dialog: false,
      fontColor: "",
      showUseInstruction: false,
      noticeTitle: "",
      noticeText: ""
    };
  },
  methods: {
    curTab(item) {
      this.$emit("curTabEvent", item);
    }
  }
};
</script>

<style lang="less" scoped>
/deep/ #noticeContent {
  pre {
    white-space: pre-wrap; /*css-3*/
    white-space: -moz-pre-wrap; /*Mozilla,since1999*/
    white-space: -pre-wrap; /*Opera4-6*/
    white-space: -o-pre-wrap; /*Opera7*/
    word-wrap: break-word; /*InternetExplorer5.5+*/
  }
}
</style>
