<template>
  <div>
    <iframe ID="Frame1" :src="url" frameborder="no" scrolling="no"  style="height: 500px;"> </iframe>
  </div>
</template>


<script>
import { api_request } from "@/util/network";

export default {
  name: "PcOuth2EmbedCard",
  props: ["sonData", "curId", "curItem", "fromType", "curTabItem"],
  data() {
    return {
      url: ""
    };
  },
  created() {
    console.log("this.curId === ", this.curTabItem.id)
    this.$http
          .get(`api/source/${this.curTabItem.id}/start`)
          .delegateTo(api_request)
          .then(data => {
           console.log(data)
            this.url = data.content;
          })
          .catch(({ code }) => {
            throw `加载失败：${this.$t("api." + code)}`;
      })

  },
  methods: {

  },
  watch: {
    curItem() {
      this.fetchData();
    },
  }
};
</script>
<style lang="scss" scoped>
</style>
